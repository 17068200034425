import CustomIcon from "../../../../icons/CustomIcon";
import React from "react";
import { AppState } from "../../../../redux/store";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

interface SubscribeButtonProps {
  destination?: string;
  onClick?: () => void;
}

const SubscribeButtonContainer = styled(Box)<{ minified: boolean }>(
  ({ theme, minified }) => ({
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    cursor: "pointer",
    marginBottom: "24px",
  })
);

const SubscribeIcon = styled(Box)(({ theme }) => ({
  width: "48px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const SubscribtionLabel = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontWeight: "400",
  fontSize: "16px",
  color: "#868b90", // fix color
  paddingLeft: "12px",

  "&:hover": {
    textDecoration: "underline",
  },
}));

const SubscribeButton: React.FC<SubscribeButtonProps> = ({ onClick }) => {
  const minified = useSelector<AppState>(
    (state) => state.settings.navCollapsed
  ) as boolean;
  return (
    <SubscribeButtonContainer
      minified={minified}
      onClick={() => onClick && onClick()}
    >
      <SubscribeIcon>
        <CustomIcon icon="lightning-circle-contained" color="#fdbd2e" />
      </SubscribeIcon>
      {!minified && <SubscribtionLabel>Subscription</SubscribtionLabel>}
    </SubscribeButtonContainer>
  );
};
export default SubscribeButton;
