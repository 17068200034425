import CollapsingItem from "./CollapsingItem";
import React from "react";
import StandardItem from "./StandardItem";
import SubItem from "./SubItem";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";

const MenuContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "100px",
  boxSizing: "border-box",
  padding: "24px",
  // letterSpacing: "0.5px",
}));

const Menu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  return (
    <MenuContainer>
      <StandardItem
        label="Dashboard"
        icon="gear"
        selected={path.search("dashboard") !== -1}
        onClick={() => navigate("/dashboard")}
      />
      <StandardItem
        label="Book Library"
        icon="glasses-02"
        selected={path.search("/books/library") !== -1}
        onClick={() => navigate("/books/library")}
      />
      <StandardItem
        label="My Texts"
        icon="file-02"
        selected={path.search("texts") !== -1}
        onClick={() => navigate("/texts")}
      />
      <CollapsingItem label="Watch" icon="tv" initiallyActive={false}>
        <SubItem
          label="Add Video"
          selected={path.search("add/video") !== -1}
          onClick={() => navigate("/add/video")}
        />
        <SubItem
          label="Video Library"
          selected={path.search("watch/library") !== -1}
          onClick={() => navigate("/watch/library")}
        />
      </CollapsingItem>
      <CollapsingItem label="Study" icon="face-id-01" initiallyActive={false}>
        <SubItem
          label="Vocabulary"
          selected={path.search("study/vocabulary") !== -1}
          onClick={() => navigate("/study/vocabulary")}
        />
        <SubItem
          label="Reviews"
          count={6}
          countColor="#a1f0d8"
          selected={path.search("study/reviews") !== -1}
          onClick={() => navigate("/study/reviews")}
        />
        <SubItem
          label="Lessons"
          last={true}
          count={8}
          countColor="#f9c39c"
          selected={path.search("study/lessons") !== -1}
          onClick={() => navigate("/study/lessons")}
        />
      </CollapsingItem>
      <StandardItem
        label="Dictionary"
        icon="file-search-02"
        selected={path.search("dictionary") !== -1}
        onClick={() => navigate("/dictionary")}
      />
    </MenuContainer>
  );
};
export default Menu;
