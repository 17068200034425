import React, { useMemo, useState } from "react";
import { Box, PopperPlacementType, styled } from "@mui/material";
import { JapaneseWord } from "./ManualReader";
import WordPopover from "./WordPopover";

interface ContentRendererProps {
  japaneseSentences: JapaneseWord[][];
  comparisonText?: string[][];
}

const ManualContentRenderer: React.FC<ContentRendererProps> = ({
  japaneseSentences,
  comparisonText = [],
}) => {
  const text = japaneseSentences
    .map((s) => s.map((w) => w.surfaceForm).join(""))
    .join(" | ");

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const [popupContent, setPopupContent] = React.useState<JapaneseWord | null>(
    null
  );
  const handleClick = useMemo(() => {
    return (newPlacement: PopperPlacementType, word: JapaneseWord) =>
      (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
        setPlacement(newPlacement);
        setPopupContent(word);
      };
  }, []);
  const handleClickAway = (e: MouseEvent | TouchEvent) => {
    console.log("className", (e as any).path[2].className);
    if ((e as any).path[2].className.search("dont-click-away") === -1) {
      setOpen(false);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "720px",
        fontSize: "26px",
        margin: "0 auto",
        fontFamily: `"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Yu Gothic", Osaka, メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif`,
        color: "#424242",
        pt: 6,
      }}
    >
      <WordPopover
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        popupContent={popupContent}
        handleClickAway={handleClickAway}
      />
      {japaneseSentences.map((sentence, i) => (
        <Sentence
          key={i}
          sentence={sentence}
          comparisonText={comparisonText?.[i] || []}
          handleClick={handleClick}
        />
      ))}
    </Box>
  );
};

const Sentence = ({
  sentence,
  comparisonText,
  handleClick,
}: {
  sentence: JapaneseWord[];
  comparisonText: string[];
  handleClick: any;
}) => {
  let cursorOffset = 0;
  let lastDecreased = 0;
  return (
    <p>
      {sentence.map((word, i) => {
        const highlight =
          comparisonText?.[i + cursorOffset] !== word.surfaceForm;

        if (highlight) {
          const compShorter =
            comparisonText?.[i + cursorOffset]?.length <
            word.surfaceForm.length;

          if (compShorter) {
            cursorOffset += 1;
          }

          if (!compShorter) {
            cursorOffset -= lastDecreased !== i - 1 ? 1 : 0;
            lastDecreased = i;
          }
        }

        return (
          <HighlightableWord
            key={i}
            word={word}
            handleClick={handleClick}
            highlightRed={highlight}
          />
        );
      })}
    </p>
  );
};

const HighlightableWord = ({
  word,
  handleClick,
  highlightRed,
}: {
  word: JapaneseWord;
  handleClick: any;
  highlightRed?: boolean;
}) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <SpanWord
      sx={highlightRed ? { color: "red" } : {}}
      className="dont-click-away"
      onClick={handleClick("bottom-start", word)}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {word.surfaceReading ? (
        <ruby>
          <span>{word.surfaceForm}</span>
          <StyledRT sx={{ visibility: isHovering ? "visible" : "hidden" }}>
            {word.surfaceReading}
          </StyledRT>
        </ruby>
      ) : (
        word.surfaceForm
      )}
    </SpanWord>
  );
};

const SpanWord = styled("span")({
  "&:hover": {
    background: "#d0f6e7",
    cursor: "pointer",
    borderRadius: "5px",
  },
});

const StyledRT = styled("rt")({
  background: "#d0f6e7",
  cursor: "pointer",
  borderRadius: "5px",
});

export default ManualContentRenderer;
