import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CopyrightDisclamer from "../../../../shared/components/CopyrightDisclamer";
import CssBaseline from "@mui/material/CssBaseline";
import CustomIcon from "../../../../icons/CustomIcon";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Auth } from "aws-amplify";
import { SignUpPageStates } from "../SignUp";

interface ConfirmSignUpProps {
  setPageState: (pageState: SignUpPageStates) => void;
  username: string;
}

const ConfirmSignUp: React.FC<ConfirmSignUpProps> = ({
  setPageState,
  username,
}) => {
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const code = data.get("code")?.toString() || "";
    try {
      await Auth.confirmSignUp(username, code, { forceAliasCreation: false });
    } catch (error) {
      console.log("error confirming sign up", error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box m={2}>
          <CustomIcon icon="yomimasu-logo" width={36} height={36} />
        </Box>
        <Typography component="h1" variant="h5">
          Confirm Code
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="code"
            label="Code"
            name="code"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Confirm
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                sx={{ cursor: "pointer" }}
                onClick={() => setPageState("signup")}
                variant="body2"
              >
                Back
              </Link>
            </Grid>
            <Grid item>
              <Link href="signup" variant="body2">
                Resend code
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CopyrightDisclamer sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};
export default ConfirmSignUp;
