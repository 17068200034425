import { Auth, Hub } from "aws-amplify";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useAuthenticatedUser = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkUser();
    setAuthListener();
  }, []);

  const checkUser = async () => {
    try {
      setLoading(true);
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
    } catch {
      setUser(null);
    }
    setLoading(false);
  };

  const setAuthListener = async () => {
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
        case "cognitoHostedUI":
          return checkUser();
        case "signOut":
          setUser(null);
          return;
        default:
          return;
      }
    });
  };
  return {
    user,
    loading,
  };
};

// Rename it to something like protected routes
export const useRedirectIfAuthenticated = (user: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user && !["/signin", "/signup"].includes(location.pathname)) {
      navigate("/signin", { replace: true });
    }
    if (user && ["/signin", "/signup"].includes(location.pathname)) {
      navigate("/dashboard", { replace: true });
    }
  }, [user, location.pathname, navigate]);
};
