import React from "react";
import SubscribeButton from "./SubscribeButton";
import UserProfileButton from "./UserProfileButton";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const SidebarFooterContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "24px",
  boxSizing: "border-box",
  position: "absolute",
  bottom: 0,
}));

const SidebarFooter: React.FC = () => {
  const navigate = useNavigate();
  return (
    <SidebarFooterContainer>
      <SubscribeButton onClick={() => navigate("/subscription")} />
      <UserProfileButton
        name="Damin"
        lastName="Matkowski"
        email="getrasa4@gmail.com"
        onClick={() => navigate("/profile")}
      />
    </SidebarFooterContainer>
  );
};
export default SidebarFooter;
