import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import CopyrightDisclamer from "../../../../shared/components/CopyrightDisclamer";
import CssBaseline from "@mui/material/CssBaseline";
import CustomIcon from "../../../../icons/CustomIcon";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Divider, Stack } from "@mui/material";
import { SignUpPageStates } from "../SignUp";

interface SignUpProps {
  setPageState: (pageState: SignUpPageStates) => void;
  setUsername: (username: string) => void;
}

const SignUpForm: React.FC<SignUpProps> = ({ setPageState, setUsername }) => {
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const firstName = data.get("firstName")?.toString() || "";
    const lastName = data.get("lastName")?.toString() || "";
    const email = data.get("email")?.toString() || "";
    const password = data.get("password")?.toString() || "";

    try {
      const username = email.replace("@", "_").replace(".", "_");
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email: email,
          given_name: firstName,
          family_name: lastName,
        },
        autoSignIn: {
          enabled: true,
        },
      });
      if (user) {
        setUsername(email);
        setPageState("confirmSignUp");
      }
    } catch (error) {
      console.log("error signing up:", error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box m={2}>
          <CustomIcon icon="yomimasu-logo" width={36} height={36} />
        </Box>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Stack direction="row" spacing={2} mt={5} width={"100%"}>
          <Button
            fullWidth
            size="large"
            color="inherit"
            variant="outlined"
            sx={{ color: "#df3e30" }}
            onClick={() =>
              Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Google,
              })
            }
          >
            Google
          </Button>
        </Stack>
        <Divider sx={{ mt: 2, width: "100%" }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            OR
          </Typography>
        </Divider>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="signin" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CopyrightDisclamer sx={{ mt: 5 }} />
    </Container>
  );
};
export default SignUpForm;
