import { Setting } from "../../types/models/Settings";
import { createSlice } from "@reduxjs/toolkit";

const initialSettings: Setting = {
  navCollapsed: false,
  initialPath: "/",
};

const Settings = createSlice({
  name: "settings",
  initialState: initialSettings,
  reducers: {
    toggleNavCollapsed: (state) => {
      state.navCollapsed = !state.navCollapsed;
    },
  },
});
export const { toggleNavCollapsed } = Settings.actions;
export default Settings.reducer;
