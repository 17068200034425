import AddBook from "../../../components/AddBook";
import ManualContentRenderer from "./ManualContentRenderer";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import ManualAddFile from "./ManualAddFile";

export interface JapaneseWord {
  surfaceForm: string;
  surfaceReading?: string;
  basicForm?: string;
  basicReading?: string;
  furigana?: { [index: number]: string };
  deflections?: string[];
  translations?: WordTranslation[];
  kuromojiPos?: string;
  word_id?: number;
}

export interface WordTranslation {
  pos: string;
  values: string[][];
}

const ManualReader = (): JSX.Element => {
  const [selectedSegmentedFile, setSegmentedTextFile] = useState<Blob | null>(
    null
  );
  const [selectedComparisonFile, setComparisonTextFile] = useState<Blob | null>(
    null
  );
  const [segmentedText, setSegmentedText] = useState<string>("");
  const [comparisonText, setComparisonText] = useState<string>("");

  useEffect(() => {
    const loadTextFromFile = async (
      file: Blob,
      setText: React.Dispatch<React.SetStateAction<string>>
    ) => {
      const text = await file.text();
      setText(text);
    };
    if (selectedSegmentedFile) {
      loadTextFromFile(selectedSegmentedFile, setSegmentedText);
    }
    if (selectedComparisonFile) {
      loadTextFromFile(selectedComparisonFile, setComparisonText);
    }
  }, [selectedSegmentedFile, selectedComparisonFile]);

  const parsedSegmentedText: JapaneseWord[][] =
    segmentedText !== "" ? JSON.parse(segmentedText) : [];

  const parsedComparisonText: string[][] =
    comparisonText !== "" ? JSON.parse(comparisonText) : [];

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100vh",
        display: "flex",
      }}
    >
      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
        }}
      >
        {<ManualAddFile setSelectedFile={setSegmentedTextFile} />}
        {
          <ManualAddFile
            setSelectedFile={setComparisonTextFile}
            offsetLeft={52}
          />
        }
        <Box sx={{ margin: "0 auto" }}>
          <ManualContentRenderer
            japaneseSentences={parsedSegmentedText}
            comparisonText={parsedComparisonText}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ManualReader;
