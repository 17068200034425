import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import CopyrightDisclamer from "../../../../shared/components/CopyrightDisclamer";
import CssBaseline from "@mui/material/CssBaseline";
import CustomIcon from "../../../../icons/CustomIcon";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Divider, Stack } from "@mui/material";
import { SignInPageStates } from "../SignIn";

interface SignInFormProps {
  setPageState: (pageState: SignInPageStates) => void;
}

const SignInForm: React.FC<SignInFormProps> = ({ setPageState }) => {
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
    const email = data.get("email")?.toString() || "";
    const password = data.get("password")?.toString() || "";
    try {
      const response = await Auth.signIn(email, password);
      console.log("Auth Response", response);
    } catch (error) {
      console.log("error signing in:", error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box m={2}>
          <CustomIcon icon="yomimasu-logo" width={36} height={36} />
        </Box>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Stack direction="row" spacing={2} mt={5} width={"100%"}>
          <Button
            fullWidth
            size="large"
            color="inherit"
            variant="outlined"
            sx={{ color: "#df3e30" }}
            onClick={() =>
              Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Google,
              })
            }
          >
            Google
          </Button>
        </Stack>
        <Divider sx={{ mt: 2, width: "100%" }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            OR
          </Typography>
        </Divider>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                sx={{ cursor: "pointer" }}
                onClick={() => setPageState("forgotPassword")}
                variant="body2"
              >
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CopyrightDisclamer sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};
export default SignInForm;
