import ConfirmSignUp from "./SignUp/ConfirmSignUp";
import SignUpForm from "./SignUp/SignUpForm";
import { useState } from "react";

export type SignUpPageStates = "signup" | "confirmSignUp";

const SignUp = () => {
  const [username, setUsername] = useState<string>("");
  const [pageState, setPageState] = useState<SignUpPageStates>("signup");

  if (pageState === "confirmSignUp")
    return (
      <ConfirmSignUp
        setPageState={setPageState}
        username={username}
      />
    );
  else
    return (
      <SignUpForm
        setPageState={setPageState}
        setUsername={setUsername}
      />
    );
};
export default SignUp;
