export const tag_bank: any = [
  ["news", "frequent", -2, "appears frequently in Mainichi Shimbun", 0],
  ["ichi", "frequent", -2, "listed as common in Ichimango Goi Bunruishuu", 0],
  ["spec", "frequent", -2, "common words not included in frequency lists", 0],
  ["gai", "frequent", -2, "common loanword", 0],
  ["P", "popular", -10, "popular term", 10],
  ["yoji", "", 0, "yojijukugo", 0],
  ["v5r", "partOfSpeech", -3, "Godan verb with 'ru' ending", 0],
  ["med", "", 0, "medicine", 0],
  ["v4h", "partOfSpeech", -3, "Yodan verb with 'hu/fu' ending (archaic)", 0],
  ["ling", "", 0, "linguistics", 0],
  ["law", "", 0, "law", 0],
  ["v2m-k", "", 0, "Nidan verb (upper class) with 'mu' ending (archaic)", 0],
  ["finc", "", 0, "finance", 0],
  ["archit", "", 0, "architecture, building", 0],
  ["MA", "", 0, "martial arts", 0],
  ["v4b", "partOfSpeech", -3, "Yodan verb with 'bu' ending (archaic)", 0],
  ["tsug", "", 0, "Tsugaru-ben", 0],
  ["pref", "partOfSpeech", -3, "prefix", 0],
  ["v4n", "", 0, "Yodan verb with 'nu' ending (archaic)", 0],
  ["abbr", "", 0, "abbreviation", 0],
  ["v-unspec", "", 0, "verb unspecified", 0],
  ["vr", "partOfSpeech", -3, "irregular ru verb, plain form ends with -ri", 0],
  ["relig", "", 0, "religion", 0],
  ["grmyth", "", 0, "Greek mythology", 0],
  ["phil", "", 0, "philosophy", 0],
  ["ateji", "", 0, "ateji (phonetic) reading", 0],
  [
    "v2t-k",
    "partOfSpeech",
    -3,
    "Nidan verb (upper class) with 'tsu' ending (archaic)",
    0,
  ],
  ["go", "", 0, "go (game)", 0],
  ["col", "", 0, "colloquialism", 0],
  ["sens", "", 0, "sensitive", 0],
  ["adv", "partOfSpeech", -3, "adverb (fukushi)", 0],
  ["bot", "", 0, "botany", 0],
  ["exp", "expression", -5, "expressions (phrases, clauses, etc.)", 0],
  ["sumo", "", 0, "sumo", 0],
  [
    "v2s-s",
    "partOfSpeech",
    -3,
    "Nidan verb (lower class) with 'su' ending (archaic)",
    0,
  ],
  ["econ", "", 0, "economics", 0],
  ["id", "expression", -5, "idiomatic expression", 0],
  ["organization", "", 0, "organization name", 0],
  ["serv", "", 0, "service", 0],
  ["cryst", "", 0, "crystallography", 0],
  ["genet", "", 0, "genetics", 0],
  ["station", "", 0, "railway station", 0],
  ["v4r", "partOfSpeech", -3, "Yodan verb with 'ru' ending (archaic)", 0],
  ["v5k-s", "partOfSpeech", -3, "Godan verb - Iku/Yuku special class", 0],
  ["kyu", "", 0, "Kyuushuu-ben", 0],
  ["astron", "", 0, "astronomy", 0],
  ["logic", "", 0, "logic", 0],
  ["product", "", 0, "product name", 0],
  ["n-suf", "partOfSpeech", -3, "noun, used as a suffix", 0],
  ["v5n", "partOfSpeech", -3, "Godan verb with 'nu' ending", 0],
  ["thb", "", 0, "Touhoku-ben", 0],
  ["tsb", "", 0, "Tosa-ben", 0],
  ["stat", "", 0, "statistics", 0],
  ["arch", "archaism", -4, "archaism", 0],
  ["given", "", 0, "given name or forename, gender not specified", 0],
  ["aux-v", "partOfSpeech", -3, "auxiliary verb", 0],
  ["hob", "", 0, "Hokkaido-ben", 0],
  ["adj-pn", "partOfSpeech", -3, "pre-noun adjectival (rentaishi)", 0],
  ["v5m", "partOfSpeech", -3, "Godan verb with 'mu' ending", 0],
  ["adj-nari", "partOfSpeech", -3, "archaic/formal form of na-adjective", 0],
  ["telec", "", 0, "telecommunications", 0],
  ["biochem", "", 0, "biochemistry", 0],
  ["embryo", "", 0, "embryology", 0],
  ["ornith", "", 0, "ornithology", 0],
  ["v1", "partOfSpeech", -3, "Ichidan verb", 0],
  ["nab", "", 0, "Nagano-ben", 0],
  ["joc", "", 0, "jocular, humorous term", 0],
  ["adj-i", "partOfSpeech", -3, "adjective (keiyoushi)", 0],
  ["adj-ku", "partOfSpeech", -3, "'ku' adjective (archaic)", 0],
  ["osb", "", 0, "Osaka-ben", 0],
  ["v4g", "", 0, "Yodan verb with 'gu' ending (archaic)", 0],
  ["pn", "partOfSpeech", -3, "pronoun", 0],
  [
    "v2m-s",
    "partOfSpeech",
    -3,
    "Nidan verb (lower class) with 'mu' ending (archaic)",
    0,
  ],
  ["vs-s", "partOfSpeech", -3, "suru verb - special class", 0],
  ["rare", "", 0, "rare", 0],
  ["adj-f", "partOfSpeech", -3, "noun or verb acting prenominally", 0],
  ["adj-shiku", "partOfSpeech", -3, "'shiku' adjective (archaic)", 0],
  ["comp", "", 0, "computing", 0],
  ["proverb", "", 0, "proverb", 0],
  ["aux-adj", "partOfSpeech", -3, "auxiliary adjective", 0],
  [
    "v2r-s",
    "partOfSpeech",
    -3,
    "Nidan verb (lower class) with 'ru' ending (archaic)",
    0,
  ],
  ["chn", "", 0, "children's language", 0],
  ["photo", "", 0, "photography", 0],
  ["adv-to", "partOfSpeech", -3, "adverb taking the 'to' particle", 0],
  ["n-adv", "partOfSpeech", -3, "adverbial noun (fukushitekimeishi)", 0],
  ["vi", "partOfSpeech", -3, "intransitive verb", 0],
  ["aviat", "", 0, "aviation", 0],
  ["place", "", 0, "place name", 0],
  ["uK", "", 0, "word usually written using kanji alone", 0],
  ["pathol", "", 0, "pathology", 0],
  ["num", "partOfSpeech", -3, "numeric", 0],
  ["hum", "", 0, "humble (kenjougo) language", 0],
  ["chem", "", 0, "chemistry", 0],
  ["company", "", 0, "company name", 0],
  ["adj-t", "partOfSpeech", -3, "'taru' adjective", 0],
  ["bus", "", 0, "business", 0],
  ["anat", "", 0, "anatomy", 0],
  ["physiol", "", 0, "physiology", 0],
  ["creat", "", 0, "creature", 0],
  ["net-sl", "", 0, "Internet slang", 0],
  ["sl", "", 0, "slang", 0],
  [
    "v2g-k",
    "partOfSpeech",
    -3,
    "Nidan verb (upper class) with 'gu' ending (archaic)",
    0,
  ],
  [
    "v2r-k",
    "partOfSpeech",
    -3,
    "Nidan verb (upper class) with 'ru' ending (archaic)",
    0,
  ],
  ["rkb", "", 0, "Ryuukyuu-ben", 0],
  ["v5uru", "", 0, "Godan verb - Uru old class verb (old form of Eru)", 0],
  ["hanaf", "", 0, "hanafuda", 0],
  ["shogi", "", 0, "shogi", 0],
  ["Christn", "", 0, "Christianity", 0],
  ["geol", "", 0, "geology", 0],
  ["adj-kari", "", 0, "'kari' adjective (archaic)", 0],
  ["prt", "partOfSpeech", -3, "particle", 0],
  [
    "v2t-s",
    "partOfSpeech",
    -3,
    "Nidan verb (lower class) with 'tsu' ending (archaic)",
    0,
  ],
  ["fish", "", 0, "fishing", 0],
  [
    "v2n-s",
    "partOfSpeech",
    -3,
    "Nidan verb (lower class) with 'nu' ending (archaic)",
    0,
  ],
  ["unclass", "", 0, "unclassified name", 0],
  ["obsc", "", 0, "obscure term", 0],
  ["agric", "", 0, "agriculture", 0],
  ["golf", "", 0, "golf", 0],
  ["ev", "", 0, "event", 0],
  ["fam", "", 0, "familiar language", 0],
  ["vulg", "", 0, "vulgar expression or word", 0],
  ["adj-ix", "partOfSpeech", -3, "adjective (keiyoushi) - yoi/ii class", 0],
  ["ecol", "", 0, "ecology", 0],
  ["met", "", 0, "climate, weather", 0],
  ["paleo", "", 0, "paleontology", 0],
  ["dated", "", 0, "dated term", 0],
  [
    "v2k-k",
    "partOfSpeech",
    -3,
    "Nidan verb (upper class) with 'ku' ending (archaic)",
    0,
  ],
  [
    "vs",
    "partOfSpeech",
    -3,
    "noun or participle which takes the aux. verb suru",
    0,
  ],
  ["audvid", "", 0, "audio-visual", 0],
  ["quote", "", 0, "quotation", 0],
  ["int", "partOfSpeech", -3, "interjection (kandoushi)", 0],
  ["archeol", "", 0, "archeology", 0],
  ["leg", "", 0, "legend", 0],
  ["oth", "", 0, "other", 0],
  ["geogr", "", 0, "geography", 0],
  [
    "adj-na",
    "partOfSpeech",
    -3,
    "adjectival nouns or quasi-adjectives (keiyodoshi)",
    0,
  ],
  [
    "v5r-i",
    "partOfSpeech",
    -3,
    "Godan verb with 'ru' ending (irregular verb)",
    0,
  ],
  [
    "X",
    "",
    0,
    "rude or X-rated term (not displayed in educational software)",
    0,
  ],
  ["psych", "", 0, "psychology, psychiatry", 0],
  ["conj", "partOfSpeech", -3, "conjunction", 0],
  ["v2a-s", "partOfSpeech", -3, "Nidan verb with 'u' ending (archaic)", 0],
  [
    "v2w-s",
    "partOfSpeech",
    -3,
    "Nidan verb (lower class) with 'u' ending and 'we' conjugation (archaic)",
    0,
  ],
  ["math", "", 0, "mathematics", 0],
  ["v1-s", "partOfSpeech", -3, "Ichidan verb - kureru special class", 0],
  ["vn", "partOfSpeech", -3, "irregular nu verb", 0],
  ["litf", "", 0, "literary or formal term", 0],
  ["kyb", "", 0, "Kyoto-ben", 0],
  ["ent", "", 0, "entomology", 0],
  ["person", "", 0, "full name of a particular person", 0],
  ["v4s", "partOfSpeech", -3, "Yodan verb with 'su' ending (archaic)", 0],
  ["v5aru", "partOfSpeech", -3, "Godan verb - -aru special class", 0],
  ["engr", "", 0, "engineering", 0],
  ["pol", "", 0, "polite (teineigo) language", 0],
  ["art", "", 0, "art, aesthetics", 0],
  ["n-pref", "partOfSpeech", -3, "noun, used as a prefix", 0],
  ["v4t", "partOfSpeech", -3, "Yodan verb with 'tsu' ending (archaic)", 0],
  ["ktb", "", 0, "Kantou-ben", 0],
  ["derog", "", 0, "derogatory", 0],
  ["hist", "", 0, "historical term", 0],
  ["obj", "", 0, "object", 0],
  ["poet", "", 0, "poetical term", 0],
  ["work", "", 0, "work of art, literature, music, etc. name", 0],
  [
    "v2h-k",
    "partOfSpeech",
    -3,
    "Nidan verb (upper class) with 'hu/fu' ending (archaic)",
    0,
  ],
  ["vs-c", "partOfSpeech", -3, "su verb - precursor to the modern suru", 0],
  ["oK", "", 0, "word containing out-dated kanji", -5],
  ["surname", "", 0, "family or surname", 0],
  ["vs-i", "partOfSpeech", -3, "suru verb - included", 0],
  [
    "vz",
    "partOfSpeech",
    -3,
    "Ichidan verb - zuru verb (alternative form of -jiru verbs)",
    0,
  ],
  ["io", "", 0, "irregular okurigana usage", -5],
  ["gardn", "", 0, "gardening, horticulture", 0],
  ["geom", "", 0, "geometry", 0],
  ["mahj", "", 0, "mahjong", 0],
  ["char", "", 0, "character", 0],
  ["m-sl", "", 0, "manga slang", 0],
  ["uk", "", 0, "word usually written using kana alone", 0],
  ["ctr", "partOfSpeech", -3, "counter", 0],
  ["ksb", "", 0, "Kansai-ben", 0],
  ["physics", "", 0, "physics", 0],
  ["obs", "", 0, "obsolete term", 0],
  ["v5k", "partOfSpeech", -3, "Godan verb with 'ku' ending", 0],
  ["gramm", "", 0, "grammar", 0],
  ["n-pr", "partOfSpeech", -3, "proper noun", 0],
  ["suf", "partOfSpeech", -3, "suffix", 0],
  [
    "v2b-k",
    "partOfSpeech",
    -3,
    "Nidan verb (upper class) with 'bu' ending (archaic)",
    0,
  ],
  [
    "v2y-s",
    "partOfSpeech",
    -3,
    "Nidan verb (lower class) with 'yu' ending (archaic)",
    0,
  ],
  ["mech", "", 0, "mechanical engineering", 0],
  ["print", "", 0, "printing", 0],
  ["sports", "", 0, "sports", 0],
  ["fem", "", 0, "female term or language", 0],
  [
    "v2k-s",
    "partOfSpeech",
    -3,
    "Nidan verb (lower class) with 'ku' ending (archaic)",
    0,
  ],
  [
    "v2y-k",
    "partOfSpeech",
    -3,
    "Nidan verb (upper class) with 'yu' ending (archaic)",
    0,
  ],
  ["mil", "", 0, "military", 0],
  ["v5b", "partOfSpeech", -3, "Godan verb with 'bu' ending", 0],
  ["vt", "partOfSpeech", -3, "transitive verb", 0],
  [
    "adj-no",
    "partOfSpeech",
    -3,
    "nouns which may take the genitive case particle 'no'",
    0,
  ],
  ["Shinto", "", 0, "Shinto", 0],
  ["unc", "partOfSpeech", -3, "unclassified", 0],
  [
    "gikun",
    "",
    0,
    "gikun (meaning as reading) or jukujikun (special kanji reading)",
    0,
  ],
  ["pharm", "", 0, "pharmacy", 0],
  ["vidg", "", 0, "video game", 0],
  ["v5s", "partOfSpeech", -3, "Godan verb with 'su' ending", 0],
  ["horse", "", 0, "horse-racing", 0],
  ["fict", "", 0, "fiction", 0],
  ["electr", "", 0, "electronics", 0],
  ["male", "", 0, "male term or language", 0],
  ["on-mim", "", 0, "onomatopoeic or mimetic word", 0],
  [
    "v2d-s",
    "partOfSpeech",
    -3,
    "Nidan verb (lower class) with 'dzu' ending (archaic)",
    0,
  ],
  ["v4k", "partOfSpeech", -3, "Yodan verb with 'ku' ending (archaic)", 0],
  ["Buddh", "", 0, "Buddhism", 0],
  ["v2d-k", "", 0, "Nidan verb (upper class) with 'dzu' ending (archaic)", 0],
  ["v5u", "partOfSpeech", -3, "Godan verb with 'u' ending", 0],
  ["dei", "", 0, "deity", 0],
  [
    "v5u-s",
    "partOfSpeech",
    -3,
    "Godan verb with 'u' ending (special class)",
    0,
  ],
  ["tradem", "", 0, "trademark", 0],
  ["iK", "", 0, "word containing irregular kanji usage", -5],
  ["cop", "", 0, "copula", 0],
  ["baseb", "", 0, "baseball", 0],
  ["hon", "", 0, "honorific or respectful (sonkeigo) language", 0],
  ["n", "partOfSpeech", -3, "noun (common) (futsuumeishi)", 0],
  [
    "v2h-s",
    "partOfSpeech",
    -3,
    "Nidan verb (lower class) with 'hu/fu' ending (archaic)",
    0,
  ],
  ["v5g", "partOfSpeech", -3, "Godan verb with 'gu' ending", 0],
  ["music", "", 0, "music", 0],
  [
    "v2g-s",
    "partOfSpeech",
    -3,
    "Nidan verb (lower class) with 'gu' ending (archaic)",
    0,
  ],
  ["v4m", "partOfSpeech", -3, "Yodan verb with 'mu' ending (archaic)", 0],
  ["elec", "", 0, "electricity, elec. eng.", 0],
  ["ok", "", 0, "out-dated or obsolete kana usage", -5],
  ["v2b-s", "", 0, "Nidan verb (lower class) with 'bu' ending (archaic)", 0],
  ["zool", "", 0, "zoology", 0],
  ["ik", "", 0, "word containing irregular kana usage", -5],
  ["myth", "", 0, "mythology", 0],
  ["aux", "partOfSpeech", -3, "auxiliary", 0],
  [
    "v2z-s",
    "partOfSpeech",
    -3,
    "Nidan verb (lower class) with 'zu' ending (archaic)",
    0,
  ],
  ["vk", "partOfSpeech", -3, "Kuru verb - special class", 0],
  ["food", "", 0, "food, cooking", 0],
  ["n-t", "partOfSpeech", -3, "noun (temporal) (jisoumeishi)", 0],
  ["v5t", "partOfSpeech", -3, "Godan verb with 'tsu' ending", 0],
  ["biol", "", 0, "biology", 0],
];
