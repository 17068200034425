import CustomIcon from "../../../../icons/CustomIcon";
import React from "react";
import { AppState } from "../../../../redux/store";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { toggleNavCollapsed } from "../../../../redux/reducers/Settings";
import { useDispatch, useSelector } from "react-redux";

// rename to NavCollapseIcon
interface NavCollapseIconProps {
  onClick?: () => void;
}

const NavCollapseIconContainer = styled(Box)<{
  minified: boolean;
}>(({ theme, minified }) => ({
  width: "24px",
  height: "24px",
  backgroundColor: "#fcfcfc", // fix color
  borderRadius: "12px",

  position: "absolute",
  top: "20px",
  right: "-12px",
  zIndex: 1000,
  boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.25)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: "#efefef",
  },
}));

const NavCollapseIcon: React.FC<NavCollapseIconProps> = ({ onClick }) => {
  const minified = useSelector<AppState>(
    (state) => state.settings.navCollapsed
  ) as boolean;
  const dispatch = useDispatch();
  return (
    <NavCollapseIconContainer
      onClick={() => dispatch(toggleNavCollapsed())}
      minified={minified}
    >
      {minified ? (
        <CustomIcon icon="chevron-right" color="#2d2f33" />
      ) : (
        <CustomIcon icon="chevron-left" color="#2d2f33" />
      )}
    </NavCollapseIconContainer>
  );
};
export default NavCollapseIcon;
