import CustomIcon from "../../../../icons/CustomIcon";
import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/store";

interface StandardItemProps {
  label: string;
  destination?: string;
  icon: string;
  selected?: boolean;
  onClick?: () => void;
}

const StandardItemContainer = styled(Box)<{
  selected: boolean;
  minified: boolean;
}>(({ theme, selected, minified }) => ({
  width: minified ? "fit-content" : "100%",
  height: "48px",
  backgroundColor: selected ? "#efefef" : "#fcfcfc", // fix color
  borderRadius: "8px",
  marginBottom: "8px",
  padding: "0px 12px",
  boxSizing: "border-box",
  color: selected ? "#2d2f33" : "#868b90",
  display: "flex",
  alignItems: "center",
  fontWeight: "500",
  fontSize: "16px",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: "#efefef",
    color: "#2d2f33",
  },
}));

const StyledLabel = styled("label")<{ minified: boolean }>(
  ({ theme, minified }) => ({
    ...(minified && { display: "none" }),
  })
);

const StandardItem: React.FC<StandardItemProps> = ({
  label,
  icon,
  selected = false,
  onClick,
}) => {
  const [isHovering, setHovering] = React.useState(false);
  const minified = useSelector<AppState>(
    (state) => state.settings.navCollapsed
  ) as boolean;
  return (
    <StandardItemContainer
      onClick={() => onClick && onClick()}
      selected={selected}
      minified={minified}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onDoubleClick={(e) => e.preventDefault()}
    >
      <Box>
        <CustomIcon
          icon={icon}
          color={selected || isHovering ? "#2d2f33" : "#868b90"}
        />
      </Box>
      <StyledLabel sx={{ ml: 2 }} minified={minified}>
        {label}
      </StyledLabel>
    </StandardItemContainer>
  );
};
export default StandardItem;
