import {
  Box,
  ClickAwayListener,
  Paper,
  Popper,
  PopperPlacementType,
} from "@mui/material";
import { JapaneseWord } from "../EpubTypes";
import { tag_bank } from "./tag_bank";

interface WordPopover {
  open: boolean;
  anchorEl: HTMLElement | null;
  placement: PopperPlacementType | undefined;
  popupContent: JapaneseWord | null;
  handleClickAway: (e: MouseEvent | TouchEvent) => void;
}

const WordPopover: React.FC<WordPopover> = ({
  open,
  anchorEl,
  placement,
  popupContent,
  handleClickAway,
}) => {
  return (
    <Popper open={open} anchorEl={anchorEl} placement={placement}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Paper
          sx={{
            mt: 1,
            maxWidth: 600,
            minWidth: 350,
            border: "1px solid #0C98E9",
            borderColor: "primary.secondary",
            padding: 2,
            fontFamily: `"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Yu Gothic", Osaka, メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif`,
            boxShadow: "0px 10px 16px 0px rgba(0,0,0,0.15)",
          }}
        >
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                fontSize: 26,
                cursor: "pointer",
              }}
              onClick={(e) => console.log("WordPopover", popupContent)}
            >
              {popupContent?.basicForm || ""}
            </Box>
            <span
              style={{
                paddingLeft: "16px",
                fontSize: 20,
                color: "text.secondary",
              }}
            >
              {popupContent?.basicReading || ""}
            </span>
          </Box>
          <ul
            style={{
              marginTop: 0,
              marginLeft: "16px",
              padding: 0,
            }}
          >
            {popupContent?.translations?.map((c, i) => {
              const tags = c.pos.split(" ");
              const mTags = tags.filter((t) => t.includes("uk"));
              const gTags = tags.filter((t) => !t.includes("uk"));
              const pos = gTags.map(
                (t) => tag_bank.find((b: string[]) => b[0] === t)?.[3] || ""
              );
              const miscellaneous = mTags.map(
                (t) => tag_bank.find((b: string[]) => b[0] === t)?.[3] || ""
              );
              const partOfSpeeches = pos.join(" & ");
              const glossaries: string[] = c.values.map((v) => v.join(", "));
              // const miscellaneous = senseElement.miscellaneous.join(", ");
              // const partOfSpeeches = senseElement.partOfSpeeches.join(" & ");
              // const glossaries = senseElement.glossaries.join("; ");
              return (
                <>
                  {partOfSpeeches && (
                    <>
                      <Box
                        sx={{
                          left: "-16px",
                          position: "relative",
                          fontWeight: 600,
                          fontSize: 13,
                          fontFamily: "Roboto",
                          // fontFamily: "PT Sans,Open Sans,Arial,sans-serif",
                          color: "text.disabled",
                          // color: "grey.500",
                          mt: i === 0 ? 1 : 2,
                          mb: 0.5,
                        }}
                      >
                        {partOfSpeeches}
                      </Box>
                      {miscellaneous && i === 0 && (
                        <Box
                          sx={{
                            left: "-16px",
                            position: "relative",
                            fontWeight: 500,
                            fontSize: 11,
                            fontFamily: "Roboto",
                            // fontFamily: "PT Sans,Open Sans,Arial,sans-serif",
                            color: "text.disabled",
                            // color: "grey.400",
                            mt: 1,
                            mb: 0.5,
                          }}
                        >
                          {miscellaneous}
                        </Box>
                      )}
                    </>
                  )}
                  {glossaries.map((glossary: string, i: number) => (
                    <li
                      style={{ marginBottom: "8px", fontSize: "13px" }}
                      key={i}
                    >
                      <Box
                        sx={{
                          fontSize: 15,
                          position: "relative",
                          left: "-4px",
                          fontFamily: "Roboto",
                          color: "#535d69",
                          lineHeight: 1,
                        }}
                      >{`${glossary};`}</Box>
                    </li>
                  ))}
                </>
              );
            })}
          </ul>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};
export default WordPopover;
