import { Box, styled } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useBook } from "../../../api/bookReader";
import ContentRenderer from "./ContentRenderer";
import { IBook } from "./EpubTypes";
import LeftTopbar from "./LeftTopbar";
import RightTopbar from "./RightTopbar";

const BookReaderContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "100vh",
  display: "flex",
}));

const BookReader = () => {
  const [sectionIndex, setSectionIndex] = useState(0);
  const { id } = useParams();
  const book: IBook = useBook(id || "").data;

  return (
    <BookReaderContainer>
      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
        }}
      >
        <LeftTopbar
          sectionIndex={sectionIndex}
          setSectionIndex={setSectionIndex}
          toc={book?.toc || []}
        />
        <RightTopbar
          sectionIndex={sectionIndex}
          setSectionIndex={setSectionIndex}
          toc={book?.toc || []}
        />
        <Box sx={{ margin: "0 auto" }}>
          <ContentRenderer book={book} sectionIndex={sectionIndex} />
        </Box>
      </Box>
    </BookReaderContainer>
  );
};
export default BookReader;
