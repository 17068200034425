import { Box, Button } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import AddIcon from "@mui/icons-material/Add";

interface AddBookProps {
  setSelectedFile: Dispatch<SetStateAction<Blob | null>>;
  offsetLeft?: number;
}

const ManualAddFile: React.FC<AddBookProps> = ({
  setSelectedFile,
  offsetLeft,
}) => {
  return (
    <Button
      key={"image"}
      variant="contained"
      component="label"
      sx={{
        minWidth: 48,
        maxWidth: 48,
        minHeight: 48,
        maxHeight: 48,
        borderRadius: 24,
        p: 0,
        m: 0,
        background: "#0C98E9",
        position: "absolute",
        top: 16,
        left: 16 + (offsetLeft || 0),
      }}
    >
      <AddIcon sx={{ color: "#fff" }} />
      <input
        hidden
        id="fileInput"
        type="file"
        accept=".json"
        onChange={(e: any) => setSelectedFile(e.target.files[0])}
      />
    </Button>
  );
};

export default ManualAddFile;
