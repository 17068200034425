import { useRef } from "react";
import { YouTubePlayer } from "react-youtube";

export const useYoutubePlayerState = () => {
  const playerRef = useRef<YouTubePlayer | null>(null);
  const reset = useRef(false);
  const currentTime = useRef(0);
  const timerRunning = useRef(false);

  const onPlayerReady = async (event: { target: YouTubePlayer }) => {
    playerRef.current = event.target;
    await event.target.playVideo();
    const time = await event.target.getCurrentTime();
    console.log("time", time);
  };

  const onPlayerStateChange = async (event: { target: YouTubePlayer }) => {
    const time = await event.target.getCurrentTime();
    currentTime.current = time;
    reset.current = true;
    const state = await event.target.getPlayerState();
    timerRunning.current = state === 1;
  };

  return {
    onPlayerReady,
    onPlayerStateChange,
    playerRef,
    currentTime,
    reset,
    timerRunning,
  };
};
