import appTheme from "./shared/constants/appTheme";
import BookLibrary from "./modules/pages/BookLibrary";
import BookReader from "./modules/pages/NewBookReader/BookReader";
import configureStore from "./redux/store/index";
import Layout from "./modules/layouts/standard/index";
import Main from "./modules/pages/Dashboard";
import ManualReader from "./modules/pages/BooksReading/Manual/ManualReader";
import React from "react";
import SignIn from "./modules/pages/Authentication/SignIn";
import VideoLibrary from "./modules/pages/VideoLibrary";
import WatchYoutubeVideo from "./modules/pages/WatchYoutubeVideo";
import WatchYtVideo from "./modules/pages/AddYoutubeVideo";
import { Amplify } from "aws-amplify";
import { awsConfig } from "./auth/aws-exports";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "@mui/material/styles";
import "./App.css";
import {
  useAuthenticatedUser,
  useRedirectIfAuthenticated,
} from "./auth/authHook";
import SignUp from "./modules/pages/Authentication/SignUp";

const AppWrapper: React.FunctionComponent = () => {
  Amplify.configure(awsConfig);

  const { user, loading } = useAuthenticatedUser();
  useRedirectIfAuthenticated(user);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return (
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/dashboard" element={<Main />} />
        <Route path="/books/library" element={<BookLibrary />} />
        <Route path="/study" element={<div>Study</div>} />
        <Route path="/add/video" element={<WatchYtVideo />} />
        <Route path="/watch/video" element={<WatchYoutubeVideo />} />
        <Route path="/watch/library/" element={<VideoLibrary />} />
        <Route path="*" element={<div>Error</div>} />
      </Route>
      <Route path="/reader/:id" element={<BookReader />} />
      <Route path="/library/manual" element={<ManualReader />} />
    </Routes>
  );
};
function App() {
  const store = configureStore();
  const queryClient = new QueryClient();
  return (
    <ThemeProvider theme={appTheme}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <BrowserRouter>
            <AppWrapper />
          </BrowserRouter>
        </Provider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
export default App;
