import reducers from "../reducers/reducers";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

export const history = createBrowserHistory();

export const rootReducer = combineReducers({
  ...reducers,
  router: connectRouter(history),
});
export type AppState = ReturnType<typeof rootReducer>;

const getStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: [routerMiddleware(history)],
  });

export default getStore;
