import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LibraryBook } from "../../../api/bookLibrary";
import AddBookButton from "./AddBook";
import BookCover from "./BookCover";

interface Props {
  books: LibraryBook[];
}

const BookGridContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "64px",
  display: "flex",
  flexWrap: "wrap",
  gap: "64px",
}));

const BookGrid: React.FC<Props> = ({ books }) => {
  return (
    <BookGridContainer>
      {books.map((book, i) => (
        <BookCover key={i} book={book} />
      ))}
      <AddBookButton />
    </BookGridContainer>
  );
};
export default BookGrid;
