import Logo from "./Logo";
import Menu from "./Menu";
import MinifySidebarIcon from "./NavCollapseIcon";
import React from "react";
import SidebarFooter from "./MenuFooter";
import { AppState } from "../../../../redux/store";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

const SidebarContainer = styled(Box)<{ minified: boolean }>(
  ({ theme, minified }) => ({
    minWidth: minified ? "fit-content" : "340px",
    height: "100vh",
    backgroundColor: "#fcfcfc", // fix color
    color: "#868b90", // fix color
    position: "relative",
    // fontFamily: "Roboto",
    // fontFamily: "Inter",
    fontFamily: "Poppins",
  })
);

const Sidebar: React.FC = () => {
  const minified = useSelector<AppState>(
    (state) => state.settings.navCollapsed
  ) as boolean;
  return (
    <SidebarContainer minified={minified}>
      <MinifySidebarIcon />
      <Logo />
      <Menu />
      <SidebarFooter />
    </SidebarContainer>
  );
};
export default Sidebar;
