import ForgotPassword from "./SignIn/ForgotPassword";
import ForgotPasswordSubmit from "./SignIn/ForgotPasswordSubmit";
import SignInForm from "./SignIn/SignInForm";
import { useState } from "react";

export type SignInPageStates =
  | "signin"
  | "forgotPassword"
  | "forgotPasswordSubmit";

const SignIn = () => {
  const [email, setEmail] = useState<string>("");
  const [pageState, setPageState] = useState<SignInPageStates>("signin");

  if (pageState === "forgotPassword")
    return <ForgotPassword setPageState={setPageState} setEmail={setEmail} />;
  else if (pageState === "forgotPasswordSubmit")
    return <ForgotPasswordSubmit setPageState={setPageState} email={email} />;
  else return <SignInForm setPageState={setPageState} />;
};
export default SignIn;
