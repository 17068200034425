import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CopyrightDisclamer from "../../../../shared/components/CopyrightDisclamer";
import CssBaseline from "@mui/material/CssBaseline";
import CustomIcon from "../../../../icons/CustomIcon";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Auth } from "aws-amplify";
import { SignInPageStates } from "../SignIn";

interface ForgotPasswordProps {
  setPageState: (pageState: SignInPageStates) => void;
  email: string;
}

const ForgotPasswordSubmit: React.FC<ForgotPasswordProps> = ({
  setPageState,
  email,
}) => {
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const code = data.get("code")?.toString() || "";
    const password = data.get("password")?.toString() || "";
    const confirmPassword = data.get("confirmPassword")?.toString() || "";

    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    const response = await Auth.forgotPasswordSubmit(email, code, password);
    setPageState("signin");
    console.log("Auth Response", response);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box m={2}>
          <CustomIcon icon="yomimasu-logo" width={36} height={36} />
        </Box>
        <Typography component="h1" variant="h5">
          Recover Password
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="code"
            label="Code"
            name="code"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="confirmPassword"
            label="Confirm Password"
            name="confirmPassword"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="signin" variant="body2">
                Back
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CopyrightDisclamer sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};
export default ForgotPasswordSubmit;
