import { createTheme } from "@mui/material/styles";

const appTheme = createTheme({
    palette: {
      primary: {
        main: "#3bcf96",
      },
      secondary: {
        main: "#1976d2",
        light: "#42a5f5",
        dark: "#1565c0",
        contrastText: "#fff",
      },
      text: {
        primary: "#535d69",
        secondary: "#7f8890",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });
  export default appTheme;