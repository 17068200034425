import HighlightableWord from "../NewBookReader/ContentRenderer/HighlightableWord";
import React from "react";
import WordPopover from "../BooksReading/Manual/WordPopover";
import { Box, styled } from "@mui/material";
import { ICaption } from "../../../types/models/VideoYoutube";
import { useSubtitleManager } from "./hooks/useSubtitleManager";
import { YouTubePlayer } from "react-youtube";

interface Props {
  subtitles: ICaption[];
  currentTime: React.MutableRefObject<number>;
  reset: React.MutableRefObject<boolean>;
  running: React.MutableRefObject<boolean>;
  playerRef: React.MutableRefObject<YouTubePlayer | null>;
}

const SubtitleDisplayerContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "140px",
  backgroundColor: "#fcfcfc", // fix color
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 16px",
  boxSizing: "border-box",
  fontFamily: `"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Yu Gothic", Osaka, メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif`,
  fontSize: "26px",
}));

const SubtitleDisplayer = ({
  subtitles,
  currentTime,
  reset,
  running,
  playerRef,
}: Props) => {
  const {
    paragraphRef,
    anchorEl,
    handleClick,
    handleClickAway,
    open,
    placement,
    popupContent,
    sub,
  } = useSubtitleManager({ subtitles, currentTime, reset, running, playerRef });

  return (
    <SubtitleDisplayerContainer sx={{ mt: 2 }}>
      <WordPopover
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        popupContent={popupContent.current}
        handleClickAway={handleClickAway}
      />
      <p
        ref={paragraphRef}
        style={{
          textAlign: "center",
        }}
      >
        {sub.current?.text.map((word, i) => (
          <HighlightableWord
            sx={{ py: 1 }}
            key={`w${i}`}
            word={word}
            handleClick={handleClick}
          />
        ))}
      </p>
    </SubtitleDisplayerContainer>
  );
};

export default SubtitleDisplayer;
