import axios, { AxiosError, AxiosResponse } from "axios";
import Api from "./../../auth/ApiConfig";

export interface HttpResponse<T> {
  data: T | null;
  message: string;
  isSuccess: boolean;
  code: number | null;
}

export const getAndExtractMessage = async <T>(
  url: string
): Promise<HttpResponse<T>> => {
  try {
    const response = await Api.get(url);
    return {
      data: response.data,
      message: "",
      isSuccess: true,
      code: response.status,
    };
  } catch (e) {
    const message = extractErrorMessage(e);
    const code = extractErrorCode(e);
    return { data: null, message, isSuccess: false, code };
  }
};

export const postAndExtractMessage = async <T>(
  url: string,
  payload: unknown
): Promise<HttpResponse<T>> => {
  try {
    const response = await Api.post(url, payload);
    return {
      data: response.data,
      message: "",
      isSuccess: true,
      code: response.status,
    };
  } catch (e) {
    const message = extractErrorMessage(e);
    const code = extractErrorCode(e);
    return { data: null, message, isSuccess: false, code };
  }
};

export const extractErrorMessage = (error: unknown): string => {
  const e = error as AxiosError<{ message: string }, unknown>;
  return axios.isAxiosError(e) ? e?.response?.data?.message || "" : "";
};

export const extractErrorCode = (error: unknown): number | null => {
  const e = error as AxiosError<{ statusCode: number }, unknown>;
  return axios.isAxiosError(e) ? e?.response?.data?.statusCode || null : null;
};
