import React from "react";
import { Box, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";

const BoxAppMain = ({
  children,
  sx,
}: {
  children: any;
  sx?: SxProps<Theme>;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100vh",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default BoxAppMain;
