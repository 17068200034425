import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/store";

interface SubItemProps {
  label: string;
  destination?: string;
  count?: number;
  countColor?: string;
  selected?: boolean;
  last?: boolean;
  onClick?: () => void;
}

const SubItemContainer = styled(Box)<{ selected: boolean; minified: boolean }>(
  ({ theme, selected, minified }) => ({
    width: "100%",
    height: "48px",
    backgroundColor: selected ? "#efefef" : "#fcfcfc", // fix color
    borderRadius: "8px",
    padding: "0px 12px",
    boxSizing: "border-box",
    color: selected ? "#2d2f33" : "#868b90",
    display: minified ? "none" : "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: "500",
    fontSize: "16px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#efefef",
      color: "#2d2f33",
    },
  })
);

const SubItemCount = styled(Box)<{ color: string }>(({ theme, color }) => ({
  backgroundColor: color,
  borderRadius: "4px",
  color: "#2d2f33",
  fontWeight: "600",
  padding: "2px 6px",
  fontSize: "15px",
}));

const SubItem: React.FC<SubItemProps> = ({
  label,
  selected = false,
  last,
  count,
  countColor,
  onClick,
}) => {
  const minified = useSelector<AppState>(
    (state) => state.settings.navCollapsed
  ) as boolean;
  return (
    <SubItemContainer
      mb={last ? 0 : 0.5}
      selected={selected}
      onClick={() => onClick && onClick()}
      minified={minified}
    >
      {label}
      {count && (
        <SubItemCount color={countColor || "#e7e9eb"}>{count}</SubItemCount>
      )}
    </SubItemContainer>
  );
};
export default SubItem;
