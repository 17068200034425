import { styled, SxProps } from "@mui/material";
import { useState } from "react";
import { JapaneseWord } from "../EpubTypes";

const SpanWord = styled("span")({
  "&:hover": {
    // background: "#a9ccf0",
    background: "#d0f6e7",
    cursor: "pointer",
    borderRadius: "5px",
  },
});

const StyledRT = styled("rt")({
  background: "#d0f6e7",
  cursor: "pointer",
  borderRadius: "5px",
});

// const TestElem = styled("span")({});
const HoveringSpan = styled("span")({
  position: "absolute",
  top: "-16px",
  left: "0",
  background: "#d0f6e7",
  cursor: "pointer",
  fontSize: 14,
  // width: "100%",
});

const HighlightableWord = ({
  word,
  handleClick,
  sx,
  ref,
}: {
  word: JapaneseWord;
  handleClick: any;
  sx?: SxProps;
  ref?: React.ForwardedRef<HTMLButtonElement>;
}) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <SpanWord
      ref={ref}
      className="dont-click-away"
      onClick={handleClick("bottom-start", word)}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      sx={sx}
    >
      {word.surfaceReading ? (
        <ruby>
          <span>{word.surfaceForm}</span>
          <StyledRT sx={{ visibility: isHovering ? "visible" : "hidden" }}>
            {word.surfaceReading}
          </StyledRT>
        </ruby>
      ) : (
        // <span
        //   style={{
        //     position: "relative",
        //   }}
        // >
        //   <span>{word.surfaceForm}</span>
        //   {isHovering && <HoveringSpan>{word.surfaceReading}</HoveringSpan>}
        // </span>
        word.surfaceForm
      )}
    </SpanWord>
  );
};
export default HighlightableWord;
