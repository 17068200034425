import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { TableOfContents } from "../../../types/models/EpubBook";

interface LeftTopbarProps {
  setSectionIndex: (pageIndex: number) => void;
  sectionIndex: number;
  // toc: [string, string][];
  toc: { chapter: string; href: string }[];
}

const LeftTopbar: React.FC<LeftTopbarProps> = ({
  setSectionIndex,
  sectionIndex,
  toc,
}) => {
  const navigate = useNavigate();

  const chapterToIndex = (chapter: string) => +chapter.replace("part", "");

  const chapters = toc.map((x) => ({
    index: chapterToIndex(x.href) + 1,
    label: x.chapter,
  }));
  chapters.unshift({ index: 0, label: "Cover" });

  const chapterIndex = Math.max(
    ...(chapters
      .map((x: any) => x.index)
      .filter((x: any) => x <= sectionIndex) || [])
  );

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        // height: 64,
        boxSizing: "border-box",
        color: "#ccc",
        // backgroundColor: "#eee",
        display: "flex",
        alignItems: "center",
        p: 2,
      }}
    >
      <IconButton
        sx={{
          color: "#ccc",
          "&:hover": {
            color: "text.disabled",
          },
        }}
        onClick={() => navigate("/books/library")}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <Select
        value={chapterIndex + ""}
        sx={{ ml: 2, height: 42, minWidth: 200 }}
        onChange={(e: SelectChangeEvent) => setSectionIndex(+e.target.value)}
      >
        {chapters.map((x, i) => (
          <MenuItem key={i} value={x.index}>
            {x.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default LeftTopbar;
