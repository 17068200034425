import React from "react";
import { Box, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";

const MainContainer = ({
  children,
  sx,
}: {
  children: any;
  sx?: SxProps<Theme>;
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        overflowX: "hidden",
        overflowY: "auto",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default MainContainer;
