import { Box } from "@mui/material";
import componentPaths from "./IconList";

interface Props {
  icon: string;
  width?: number;
  height?: number;
  color?: string;
  stroke?: number;
}

const CustomIcon: React.FC<Props> = ({
  icon,
  width = 24,
  height = 24,
  color = "#868b90",
  stroke = 2,
}) => {
  const originalWidth = componentPaths[icon]?.width || 0;
  const originalHeight = componentPaths[icon]?.height || 0;
  const path = componentPaths[icon]?.path(color, stroke) || null;
  const viewBox = `0 0 ${originalWidth} ${originalHeight}`;
  return (
    <Box
      width={width}
      height={height}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <svg
        width={originalWidth * (width / 24)}
        height={originalHeight * (width / 24)}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {path}
      </svg>
    </Box>
  );
};
export default CustomIcon;
