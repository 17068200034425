import { Box, Button, Select, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import SubtitleDisplayer from "./WatchVideo/SubtitleDisplayer";
import YoutubeEmbed from "./WatchVideo/YoutubeEmbed";
import YouTube, { YouTubeProps } from "react-youtube";
import {
  useGenerateYoutubeCaptions,
  useVideoInfo,
  useYoutubeCaptions,
} from "../../api/youtubeCaptions";

const WatchYtVideoContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  backgroundColor: "#e7e9eb", // fix color
  display: "flex",
  flexDirection: "column",
  maxWidth: "100%",
}));

const WatchYtVideo = () => {
  const [url, setUrl] = useState<string>(
    "https://www.youtube.com/watch?v=1VuD4HVYFEg&ab_channel=A.I.Channel"
  );
  let embedId = "5yx6BWlEVcY";
  try {
    const parsedUrl = new URL(url);
    embedId = parsedUrl.searchParams.get("v") || "5yx6BWlEVcY";
  } catch {
    console.log("invalid url");
  }

  const opts: YouTubeProps["opts"] = {
    height: "640",
    width: "100%",
  };

  const youtubeVideo = useVideoInfo(embedId)?.data;
  const youtubeCaptions =
    useYoutubeCaptions(embedId, "whisper-medium")?.data || [];
  console.log("youtubeVideo", youtubeVideo);
  console.log("youtubeCaptions", youtubeCaptions);
  const generateYoutubeCaptions = useGenerateYoutubeCaptions();
  const containsCaptions = youtubeVideo?.generatedCaptions.length !== 0;

  const onPlayerReady: YouTubeProps["onReady"] = async (event) => {
    // await event.target.playVideo();
    // await event.target.seekTo(40, true);
    const time = await event.target.getCurrentTime();
    console.log("time", time);
  };

  const reset = useRef(false);
  const currentTime = useRef(0);
  const timerRunning = useRef(false);

  const onPlayerStateChange: YouTubeProps["onStateChange"] = async (event) => {
    const time = await event.target.getCurrentTime();
    currentTime.current = time;
    reset.current = true;
    const state = await event.target.getPlayerState();
    if (state === 1) {
      timerRunning.current = true;
    } else {
      timerRunning.current = false;
    }
  };

  const availableCaptions =
    youtubeVideo?.generatedCaptions.map((c) => c.model) || [];

  return (
    <WatchYtVideoContainer px={16} boxSizing="border-box">
      <Box maxWidth="1000px">
        <TextField
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          sx={{ my: 2, background: "#fcfcfc", width: "100%" }}
        ></TextField>
        <YouTube
          videoId={embedId}
          opts={opts}
          onReady={onPlayerReady}
          onPlay={() => console.log("Playing!")}
          onPause={() => console.log("Stopped!")}
          onStateChange={onPlayerStateChange}
        />
      </Box>
      <Box sx={{ maxWidth: 1000, display: "flex", flexDirection: "column" }}>
        <Select sx={{ my: 2, background: "#fcfcfc", width: "100%" }}>
          {availableCaptions.map((caption, i) => (
            <option key={i} value={caption}>
              {caption}
            </option>
          ))}
        </Select>
        <Button
          sx={{ my: 2, background: "#fcfcfc", width: "100%" }}
          disabled={containsCaptions}
          onClick={async () =>
            await generateYoutubeCaptions.mutateAsync({
              videoId: embedId,
              model: "whisper-medium",
            })
          }
        >
          {containsCaptions ? "Captions already exist" : "Generate Captions"}
        </Button>
      </Box>
    </WatchYtVideoContainer>
  );
};
export default WatchYtVideo;
