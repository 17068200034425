import { Box } from "@mui/system";
import { useEffect, useState } from "react";

interface ContentRendererProps {
  url: string;
  center?: boolean;
  containerWidth?: number;
}

const ImageRenderer: React.FC<ContentRendererProps> = ({
  url,
  center = false,
  containerWidth = 720,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    setIsLoading(true);
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleResize);

    const img = new window.Image();
    img.src = url;
    img.onload = () => setIsLoading(false);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [url]);

  const img = new Image();
  img.src = url;
  const { src, alt, width, height } = img;

  const windowHeightWithMargin = windowHeight - 80;
  const imgRatio = width / height;
  const containerRatio = containerWidth / windowHeightWithMargin;

  const dominantSide = imgRatio > containerRatio ? "width" : "height";
  const setFullWidth = width / containerWidth > 0.5;

  let newWidth,
    newHeight = 0;
  if (dominantSide === "width") {
    newWidth = containerWidth;
    newHeight = containerWidth / imgRatio;
  } else {
    newHeight = windowHeightWithMargin;
    newWidth = newHeight * imgRatio;
  }

  console.log("width", width, "height", height);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", pt: "40px" }}>
      {isLoading ? (
        <div>loading...</div>
      ) : (
        <img
          src={src}
          alt={alt}
          width={setFullWidth ? newWidth : width}
          height={setFullWidth ? newHeight : height}
          style={
            setFullWidth ? { boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" } : {}
          }
        />
      )}
    </Box>
  );
};
export default ImageRenderer;
