import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLibraryBooks } from "../../api/bookLibrary";
import BookGrid from "./NewBookLibrary/BookGrid";
import Topbar from "./NewBookLibrary/Topbar";

const BookLibraryContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  backgroundColor: "#e7e9eb", // fix color
  display: "flex",
  flexDirection: "column",
}));

const BookLibrary = () => {
  const books = useLibraryBooks().data || [];
  console.log("Books", books);

  return (
    <BookLibraryContainer>
      <Topbar />
      <BookGrid books={books} />
    </BookLibraryContainer>
  );
};
export default BookLibrary;
