import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchBar from "./SearchBar";

const TopbarContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "64px",
  padding: "0px 60px",
  boxSizing: "border-box",
  backgroundColor: "#fcfcfc", // fix color
  borderRadius: "0px 0px 8px 8px",
  display: "flex",
  alignItems: "center",
}));

const Topbar = () => {
  return (
    <Box px={0.5}>
      <TopbarContainer>
        <SearchBar />
      </TopbarContainer>
    </Box>
  );
};
export default Topbar;
