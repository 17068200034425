import CustomIcon from "../../../../icons/CustomIcon";
import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/store";

interface CollapsingItemProps {
  label: string;
  icon: string;
  initiallyActive?: boolean;
  children?: React.ReactNode;
}

const CollapsingItemContainer = styled(Box)<{ minified: boolean }>(
  ({ theme, minified }) => ({
    width: minified ? "fit-content" : "100%",
    marginBottom: "8px",
  })
);

const ParentItemContainer = styled(Box)<{ active: boolean }>(
  ({ theme, active }) => ({
    width: "100%",
    height: "48px",
    backgroundColor: active ? "#efefef" : "#fcfcfc", // fix color
    borderRadius: "8px",
    marginBottom: "8px",
    padding: "0px 12px",
    boxSizing: "border-box",
    color: active ? "#2d2f33" : "#868b90",
    display: "flex",
    alignItems: "center",
    fontWeight: "500",
    fontSize: "16px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#efefef",
      color: "#2d2f33",
    },
  })
);

const ChildrenContainer = styled(Box)<{ active: boolean }>(
  ({ theme, active }) => ({
    width: "100%",
    display: active ? "flex" : "none",
    flexDirection: "row",
  })
);

const CollapsingLine = styled(Box)(({ theme }) => ({
  width: "6px",
  backgroundColor: "#efefef",
  margin: "0px 13px 0px 21px",
}));

const CollapsingItem: React.FC<CollapsingItemProps> = ({
  label,
  icon,
  initiallyActive = false,
  children,
}) => {
  const [isHovering, setHovering] = React.useState(false);
  const [isActive, setActive] = React.useState(initiallyActive);

  const minified = useSelector<AppState>(
    (state) => state.settings.navCollapsed
  ) as boolean;
  return (
    <CollapsingItemContainer
      onDoubleClick={(e) => e.preventDefault()}
      minified={minified}
    >
      <ParentItemContainer
        active={isActive}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        onClick={() => setActive(!isActive)}
      >
        <Box>
          <CustomIcon
            icon={icon}
            color={isActive || isHovering ? "#2d2f33" : "#868b90"}
          />
        </Box>
        {!minified && (
          <>
            <Box sx={{ ml: 2 }}>{label}</Box>
            <Box sx={{ ml: "auto" }}>
              <CustomIcon
                width={32}
                height={32}
                icon={isActive ? "chevron-up" : "chevron-down"}
                color={isActive || isHovering ? "#2d2f33" : "#868b90"}
              />
            </Box>
          </>
        )}
      </ParentItemContainer>
      {!minified && (
        <ChildrenContainer active={isActive}>
          <CollapsingLine />
          <Box display="flex" flexDirection="column" width="100%">
            {children}
          </Box>
        </ChildrenContainer>
      )}
    </CollapsingItemContainer>
  );
};
export default CollapsingItem;
