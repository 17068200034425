import AppBar from "./AppBar";
import BoxAppMain from "./BoxAppMain";
import MainContainer from "./MainContainer";
import MainContent from "./MainContent";
import Sidebar from "./NewSidebar";
import { Outlet } from "react-router";

const Layout = () => {
  return (
    <BoxAppMain>
      <Sidebar />
      <MainContainer sx={{ backgroundColor: "#f9f9fb", position: "relative" }}>
        {/* <AppBar /> */}
        <MainContent>
          <Outlet />
        </MainContent>
      </MainContainer>
    </BoxAppMain>
  );
};

export default Layout;
