import { useMutation, UseMutationResult, useQuery } from "react-query";
import Api from "../auth/ApiConfig";
import { JapaneseWord } from "../modules/pages/NewBookReader/EpubTypes";
import { CaptionUrl, ICaption, IVideoInfo } from "../types/models/VideoYoutube";
import { HttpResponse, postAndExtractMessage } from "./helpers/RestHelper";

export const useVideoInfo = (videoId: string) => {
  return useQuery<IVideoInfo, Error>(
    ["youtube-video", videoId],
    async () => {
      const response = await Api.get(`/watch/youtube/video`, {
        params: { videoId },
      });
      return response.data;
    },
    {
      ...{
        staleTime: 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
      },
    }
  );
};

export const useYoutubeCaptions = (videoId: string, model: string) => {
  return useQuery<ICaption[], Error>(
    ["youtube-captions", videoId, model],
    async () => {
      const { data } = await Api.get<
        IVideoInfo & { captionUrls: CaptionUrl[] }
      >(`/watch/youtube/captions`, {
        params: { videoId, model },
      });
      console.log("No captions found", data);
      if (!data) {
        return [];
      }
      const captionsUrl = data.captionUrls;

      const sortedCaptions = captionsUrl.sort((a, b) => a.start - b.start);
      console.log("WHAT A FUCKK!!!");
      const signedUrls = sortedCaptions.map((c) => c.signedUrl);
      console.log("Signed urls", signedUrls);

      const captions: ICaption[] = [];
      for (const c of sortedCaptions) {
        const url = c.signedUrl;
        const response = await fetch(url);
        const blob = await response.blob();
        const text = await blob.text();
        const json: ICaption[] = JSON.parse(text);
        const offsetCaption = json.map((j) => ({
          ...j,
          start: j.start + c.start,
          end: j.end + c.start,
        }));
        captions.push(...offsetCaption);
      }

      return captions;
    },
    {
      ...{
        staleTime: 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
      },
    }
  );
};

export const useGenerateYoutubeCaptions = (): UseMutationResult<
  HttpResponse<{ success: boolean }>,
  Error,
  { videoId: string; model: string },
  unknown
> => {
  return useMutation(
    async ({ videoId, model }: { videoId: string; model: string }) => {
      return await postAndExtractMessage<{
        success: boolean;
      }>("/watch/youtube/captions/generate", { videoId, model });
    }
  );
};

export const useVideoLibrary = (category = "all") => {
  return useQuery<IVideoInfo[], Error>(
    ["youtube-video", category],
    async () => {
      const response = await Api.get(`/watch/youtube/library`, {
        params: { category },
      });
      return response.data;
    },
    {
      ...{
        staleTime: 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
      },
    }
  );
};
