import { Box, InputAdornment, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomIcon from "../../../icons/CustomIcon";

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "380px",

  backgroundColor: "#f4f4f4",
  borderRadius: "8px",
  "& fieldset": {
    border: "1px solid  #e7e9eb",
    borderRadius: "8px",
    "& .hover": {
      border: "none",
    },
  },
  "& .MuiInputBase-root": {
    height: "36px",
  },

  "& .hover": {
    "& fieldset": { border: "none", borderRadius: "16px" },
  },
}));

const SearchBarContainer = styled(Box)(({ theme }) => ({
  width: "380px",
  height: "36px",
  padding: "0px 12px",
  boxSizing: "border-box",
  backgroundColor: "#f4f4f4", // fix color
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
}));

const SearchBarPlaceholder = styled(Box)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "14px",
  color: "#868b90",
}));

const SearchBar = () => {
  return (
    <Box px={0.5}>
      <StyledTextField
        placeholder="Search book"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CustomIcon
                icon="search-02"
                color="#868b90"
                width={20}
                height={20}
              />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
export default SearchBar;
