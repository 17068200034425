import React from "react";
import { AppState } from "../../../../redux/store";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

interface UserProfileButtonProps {
  name: string;
  lastName: string;
  email: string;
  destination?: string;
  onClick?: () => void;
}

const UserProfileButtonContainer = styled(Box)<{ minified: boolean }>(
  ({ theme, minified }) => ({
    width: minified ? "fit-content" : "100%",
    boxSizing: "border-box",
    display: "flex",
    cursor: "pointer",
    borderRadius: "24px",

    "&:hover": {
      backgroundColor: "#efefef",
    },
  })
);

const UserProfileImage = styled(Box)(({ theme }) => ({
  width: "48px",
  height: "48px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#37c18c", // fix color,
  borderRadius: "50%",
  fontWeight: "500",
  fontSize: "16px",
  color: "#FCFCFC", // fix color
}));

const UserName = styled(Box)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "16px",
  color: "#2d2f33", // fix color
}));

const UserEmail = styled(Box)(({ theme }) => ({
  fontWeight: "400",
  fontSize: "14px",
  color: "#868b90", // fix color
}));

const UserProfileButton: React.FC<UserProfileButtonProps> = ({
  name,
  lastName,
  email,
  onClick,
}) => {
  const initials = name[0] + lastName[0];
  const minified = useSelector<AppState>(
    (state) => state.settings.navCollapsed
  ) as boolean;
  return (
    <UserProfileButtonContainer
      minified={minified}
      onClick={() => onClick && onClick()}
    >
      <UserProfileImage>{initials}</UserProfileImage>
      {!minified && (
        <Box display="flex" flexDirection="column" p="1px 12px">
          <UserName>{name + " " + lastName}</UserName>
          <UserEmail>{email}</UserEmail>
        </Box>
      )}
    </UserProfileButtonContainer>
  );
};
export default UserProfileButton;
