import Api from "./../auth/ApiConfig";
import { useQuery } from "react-query";
import { ITag } from "../modules/pages/NewBookReader/EpubTypes";

export const useBook = (id: string) => {
  return useQuery(["book", id], async () => {
    const { data } = await Api.get(`/book-reader/${id}`);
    return data;
  });
};

export const useBookPage = (bookId: string, pageId: string) => {
  return useQuery<ITag[], Error>(["book-page", bookId, pageId], async () => {
    if (!bookId || !pageId) return;
    const { data: signedUrl } = await Api.get(
      `/book-reader/pages/${bookId}/${pageId}`
    );
    if (!signedUrl) return;
    // Download from signed url and extract text
    const response = await fetch(signedUrl);
    const blob = await response.blob();
    const text = await blob.text();
    return JSON.parse(text);
  });
};

export const useSectionImageUrls = (bookId: string, imageNames: string[]) => {
  return useQuery<string[], Error>(
    ["section-images", bookId, imageNames.join(",")],
    async () => {
      if (imageNames.length === 0) return [];
      const { data } = await Api.post("/book-reader/images", {
        bookId,
        imageNames,
      });
      return data;
    },
    {
      ...{
        staleTime: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
      },
    }
  );
};
