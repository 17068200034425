import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "react-query";
import { getAndExtractMessage, HttpResponse } from "./helpers/RestHelper";
import Api from "../auth/ApiConfig";

export interface EpubRecord {
  bookId: string;
  fileType: string;
  metadata: EpubMetadata;
  toc: {
    chapter: string;
    href: string;
  }[];
}

export interface EpubMetadata {
  title: string;
  author: string;
  identifier: string;
  language: string;
  publishedDate: string;
  publisher: string;
  tocStart: string;
  textStart: string;
}

export interface LibraryBook {
  id: string;
  bookId: string;
  coverUrl: string;
  fileType: string;
  location: { section: number };
  metadata: EpubMetadata;
  processingStatus: string;
}

export const useLibraryBooks = () => {
  return useQuery<LibraryBook[], Error>(
    ["library-books"],
    async () => {
      const response = await Api.get("/library/books");
      return response.data;
    },
    {
      ...{
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
      },
    }
  );
};

export const useGetUploadUrls = (): UseMutationResult<
  HttpResponse<{
    bookUploadUrl: string;
    coverUploadUrl: string;
    bookId: string;
  }>,
  Error,
  string,
  unknown
> => {
  return useMutation(async (fileType: string) => {
    const params = new URLSearchParams();
    params.set("fileType", fileType);
    return await getAndExtractMessage<{
      bookUploadUrl: string;
      coverUploadUrl: string;
      bookId: string;
    }>(`/library/upload-urls?${params.toString()}`);
  });
};

export const useAddBookRecord = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: EpubRecord) => {
      return await Api.post("/library/add-book-record", payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("library-books");
      },
    }
  );
};
