export const awsConfig = {
  aws_project_region: "ap-southeast-2",
  aws_cognito_identity_pool_id: "",
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id: process.env.REACT_APP_COGNITO_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_POOL_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_OATH_DOMAIN,
    scope: ["email", "openid", "profile"],
    redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT,
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
};
