import CustomIcon from "../../../../icons/CustomIcon";
import React from "react";
import { AppState } from "../../../../redux/store";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

const LogoContainer = styled(Box)<{ minified: boolean }>(
  ({ theme, minified }) => ({
    width: minified ? "fit-content" : "100%",
    height: "64px",
    backgroundColor: "fcfcfc", // fix color
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    paddingLeft: "30px",
    cursor: "pointer",
  })
);

const LogoLabel = styled(Box)(({ theme }) => ({
  fontWeight: "700",
  fontSize: "18px",
  color: "#2d2f33", // fix color
  paddingLeft: "12px",
}));

const Logo: React.FC = () => {
  const minified = useSelector<AppState>(
    (state) => state.settings.navCollapsed
  ) as boolean;
  return (
    <LogoContainer minified={minified}>
      <CustomIcon icon="yomimasu-logo" width={36} height={36} />
      {!minified && <LogoLabel>Yomimasu</LogoLabel>}
    </LogoContainer>
  );
};
export default Logo;
