import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomIcon from "../../../icons/CustomIcon";

const AddBookButtonContainer = styled(Box)(({ theme }) => ({
  width: "180px",
  height: "250px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "4px dashed #868B90",
  cursor: "pointer",
}));

const AddBookButton: React.FC = () => {
  return (
    <AddBookButtonContainer>
      <CustomIcon
        icon="plus-01"
        color="#868B90"
        width={96}
        height={96}
        stroke={0.8}
      />
    </AddBookButtonContainer>
  );
};
export default AddBookButton;
