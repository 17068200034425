import React from "react";
import { Box, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";

const MainContent = ({
  children,
  sx,
}: {
  children: any;
  sx?: SxProps<Theme>;
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 65px)",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default MainContent;
