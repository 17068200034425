import React from "react";
import { Box, Button } from "@mui/material";
import { TableOfContents } from "../../../types/models/EpubBook";

interface RightTopbarProps {
  setSectionIndex: (pageIndex: number) => void;
  sectionIndex: number;
  // toc: [string, string][];
  toc: { chapter: string; href: string }[];
}

const RightTopbar: React.FC<RightTopbarProps> = ({
  sectionIndex,
  setSectionIndex,
  toc,
}) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        right: 16,
        height: 42,
        color: "#ccc",
        display: "flex",
        alignItems: "center",
        p: 2,
      }}
    >
      <Box>
        <Button
          onClick={() => setSectionIndex(sectionIndex - 1)}
          sx={{
            border: "1px solid #ccc",
            color: "#ccc",
            padding: 0,
          }}
        >
          {"<"}
        </Button>
        <Button
          onClick={() => setSectionIndex(sectionIndex + 1)}
          sx={{
            border: "1px solid #ccc",
            color: "#ccc",
            padding: 0,
            ml: 1,
          }}
        >
          {">"}
        </Button>
      </Box>
    </Box>
  );
};

export default RightTopbar;
