import { Box } from '@mui/material';
import { IVideoInfo } from '../../../types/models/VideoYoutube';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const VideoCardContainer = styled(Box)(({ theme }) => ({
  width: "320px",
  height: "300px",
  backgroundColor: "#fcfcfc", // fix color
  display: "flex",
  flexDirection: "column",
  maxWidth: "100%",
  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
  "&:hover": {
    cursor: "pointer",
    outline: "3px solid",
    outlineColor: "#0C98E9",
    outlineOffset: "1px",
    boxSizing: "border-box",
  },
}));

const VideoTitle = styled("div")(({ theme }) => ({
  fontSize: "15px",
  fontWeight: 600,
  color: "#2d2f33", // fix color
  width: "100%",
  textOverflow: "ellipsis",
  wrapSpace: "no-wrap",
  overflow: "hidden",
  whiteSpace: "nowrap",
  padding: "0px 8px",
  boxSizing: "border-box",
  marginTop: "12px",
  fontFamily: "Inter",
}));

const VideoChannel = styled("div")(({ theme }) => ({
  fontSize: "15px",
  fontWeight: 400,
  color: "#868b90", // fix color
  width: "100%",
  textOverflow: "ellipsis",
  wrapSpace: "no-wrap",
  overflow: "hidden",
  whiteSpace: "nowrap",
  padding: "8px 8px",
  fontFamily: "Inter",
}));

interface VideoCardProps {
  video: IVideoInfo;
}

const ModelSnippet = styled(Box)(({ theme }) => ({
  padding: "6px 8px",
  backgroundColor: "#efefef", // fix color
  fontSize: "12px",
  // color: "#fff", // fix color
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px",
  width: "fit-content",
  boxSizing: "border-box",
  fontWeight: 600,
}));

const VideoCard: React.FC<VideoCardProps> = ({ video }) => {
  const navigate = useNavigate();
  const { snippet } = video;
  const { title, channelTitle, thumbnails } = snippet;
  const thumbnail = thumbnails?.medium?.url;
  const model = video.generatedCaptions?.[0].model;

  return (
    <VideoCardContainer onClick={() => navigate(`/watch/video?videoId=${video.videoId}`)}>
      <img src={thumbnail} />
      <Box mx={1.5}>
        <VideoTitle>{title}</VideoTitle>
        <VideoChannel>{channelTitle}</VideoChannel>
        <ModelSnippet>{model}</ModelSnippet>
      </Box>
    </VideoCardContainer>
  );
};
export default VideoCard;
