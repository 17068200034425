import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { LibraryBook } from "../../../api/bookLibrary";
import Topbar from "./Topbar";

interface Props {
  book: LibraryBook;
}

const BookCoverContainer = styled(Box)<{
  disabled: boolean;
  selected: boolean;
}>(({ theme, disabled, selected }) => ({
  width: "180px",
  height: "250px",
  backgroundColor: "#f4f4f4", // fix color
  display: "flex",
  cursor: "pointer",
  // get me a drop shadow of X: 0, Y: 4, B: 4, S: 0 and opacity 0.25
  boxShadow: disabled ? "none" : "0px 4px 4px rgba(0, 0, 0, 0.25)",

  "&:hover": {
    cursor: "pointer",
    // border: "0.5px solid #fff",

    // ...(disabled
    //   ? {}
    //   : {
    //       boxShadow: "none",
    //       position: "absolute",
    //       top: "2px",
    //     }),
    ...(disabled
      ? {}
      : {
          outline: "5px solid",
          outlineColor: "#0C98E9",
          outlineOffset: "1px",
          boxSizing: "border-box",
        }),
  },
}));

const BookTitle = styled("div")(({ theme }) => ({
  fontSize: "15px",
  fontWeight: 600,
  color: "#2d2f33", // fix color
  width: "100%",
  textOverflow: "ellipsis",
  wrapSpace: "no-wrap",
  overflow: "hidden",
  whiteSpace: "nowrap",
  padding: "0 4px",
  boxSizing: "border-box",
  marginTop: "12px",
  textAlign: "center",
  fontFamily: "Inter",
}));

const BookAuthor = styled("div")(({ theme }) => ({
  fontSize: "15px",
  fontWeight: 400,
  color: "#868b90", // fix color
  width: "100%",
  textOverflow: "ellipsis",
  wrapSpace: "no-wrap",
  overflow: "hidden",
  whiteSpace: "nowrap",
  padding: "0 4px",
  boxSizing: "border-box",
  textAlign: "center",
  fontFamily: "Inter",
  marginTop: "-2px",
}));

const CoverImg = styled("img")<{ disabled: boolean }>(
  ({ theme, disabled }) => ({
    width: "100%",
    ...(disabled ? { filter: "grayscale(100%)" } : {}),
  })
);

const BookCover: React.FC<Props> = ({ book }) => {
  const navigate = useNavigate();
  const disabled = book.processingStatus !== "PROCESSED";
  const onBookClick = () => {
    !disabled && navigate(`/reader/${book.bookId}`);
  };
  return (
    <Box
      position="relative"
      width="180px"
      // overflow="hidden"
      onClick={onBookClick}
    >
      <BookCoverContainer disabled={disabled} selected={false}>
        <CoverImg src={book.coverUrl} disabled={disabled} />
      </BookCoverContainer>
      <BookTitle>{book.metadata?.title || "Unknown"}</BookTitle>
      <BookAuthor>{book.metadata?.author || "Unknown"}</BookAuthor>
    </Box>
  );
};
export default BookCover;
