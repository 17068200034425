import React, { memo, useMemo, useRef, useState } from "react";
import { IBook, ITag, ITagImage, ITagTxt, JapaneseWord } from "./EpubTypes";
import { useBookPage, useSectionImageUrls } from "../../../api/bookReader";
import { Box, PopperPlacementType, styled } from "@mui/material";
import ImageRenderer from "./ContentRenderer/ImageRenderer";
import HighlightableWord from "./ContentRenderer/HighlightableWord";
import WordPopover from "./ContentRenderer/WordPopover";

interface ContentRendererProps {
  book: IBook;
  sectionIndex: number;
}

const ContentRendererContainer = styled(Box)(({ theme }) => ({
  maxWidth: "720px",
  fontSize: "26px",
  margin: "0 auto",
  fontFamily: `"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Yu Gothic", Osaka, メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif`,
  color: "#424242",
  // color: "#535d69",
  pt: 6,
}));

const ContentRenderer: React.FC<ContentRendererProps> = ({
  book,
  sectionIndex,
}) => {
  if (!book) return <></>;
  const pageTags = useBookPage(book.bookId, sectionIndex + "").data || [];
  console.log("bookPage", pageTags);

  const filterImg = (t: ITag) => t.type === "image";
  const imageTags = pageTags.filter(filterImg) as ITagImage[];
  const imageNames = imageTags.map((tag) => tag.content);
  const pageImgUrls = useSectionImageUrls(book.bookId, imageNames).data || [];

  const textTags = pageTags.filter((t) => t.type === "text") as ITagTxt[];

  const paragraphRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const [popupContent, setPopupContent] = React.useState<JapaneseWord | null>(
    null
  );
  const handleClick = useMemo(() => {
    return (newPlacement: PopperPlacementType, word: JapaneseWord) =>
      (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log("handleClick", word);
        setAnchorEl(event.currentTarget);
        setOpen(true);
        setPlacement(newPlacement);
        setPopupContent(word);
      };
  }, []);
  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (
      paragraphRef.current &&
      !paragraphRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
    // console.log("className", (e as any).path[2].className);
    // if ((e as any).path[2].className.search("dont-click-away") === -1) {
    //   setOpen(false);
    // }
  };

  const BookParagraphs = memo(() => (
    <>
      {textTags.map((tag, i) => (
        <p
          key={`p${i}`}
          style={tag.styles.includes("bold") ? { fontWeight: 600 } : {}}
        >
          {tag.content.map((word, j) => (
            <HighlightableWord
              key={`d${i}w${j}`}
              word={word}
              handleClick={handleClick}
            />
          ))}
        </p>
      ))}
    </>
  ));

  return (
    <ContentRendererContainer>
      <WordPopover
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        popupContent={popupContent}
        handleClickAway={handleClickAway}
      />

      {imageTags?.[0] && (
        <ImageRenderer url={pageImgUrls?.[0] || ""} center={true} />
      )}
      {/* <BookParagraphs /> */}
      {textTags.map((tag, i) => (
        <BookParagraph key={`p${i}`} tag={tag} handleClick={handleClick} ref={paragraphRef}/>
      ))}
      {/* {textTags.map((tag, i) => (
        <p
          key={`p${i}`}
          style={tag.styles.includes("bold") ? { fontWeight: 600 } : {}}
        >
          {tag.content.map((word, j) => (
            <HighlightableWord
              key={`d${i}w${j}`}
              word={word}
              handleClick={handleClick}
            />
          ))}
        </p>
      ))} */}
    </ContentRendererContainer>
  );
};

const BookParagraph = ({
  tag,
  handleClick,
  ref
}: {
  tag: ITagTxt;
  handleClick: any;
  ref: React.RefObject<HTMLDivElement>;
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const textContent = tag.content.map((w, i) => (
    <span key={i}>{w.surfaceForm}</span>
  ));
  return (
    <Paragraph
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      sx={tag.styles.includes("bold") ? { fontWeight: 600 } : {}}
      ref={ref}
    >
      {tag.content.map((word, j) => (
        <HighlightableWord
          key={`w${j}`}
          word={word}
          handleClick={handleClick}
        />
      ))}

      {/* {isHovering ? (
        tag.content.map((word, j) => (
          <HighlightableWord
            key={`w${j}`}
            word={word}
            handleClick={handleClick}
          />
        ))
      ) : (
        <>{textContent}</>
      )} */}
    </Paragraph>
  );
};

const Paragraph = styled("p")(({ theme }) => ({}));

export default ContentRenderer;
