import VideoCard from './VideoLibrary/VideoCard';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useVideoLibrary } from '../../api/youtubeCaptions';

const VideoLibraryContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  backgroundColor: "#e7e9eb", // fix color
  maxWidth: "100%",
  overflowY: "scroll",
}));

const VideoLibrary = () => {

  const youtubeVideos = useVideoLibrary()?.data || [];
  console.log("youtubeVideo", youtubeVideos);

  return (
    <VideoLibraryContainer px={8} py={4}>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "row", gap: "16px", flexWrap: 'wrap' }}>
        {youtubeVideos.map((video, i) => (
          <Box
            key={i}
          >
            <VideoCard key={i} video={video} />
          </Box>
        ))}
      </Box>
    </VideoLibraryContainer>
  );
};
export default VideoLibrary;
