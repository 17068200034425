import { ICaption } from "../../../../types/models/VideoYoutube";
import { JapaneseWord } from "../../NewBookReader/EpubTypes";
import { PopperPlacementType } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForceUpdate } from "../../../../shared/components/useForceUpdate";

interface SubtitleManagerProps {
  subtitles: ICaption[];
  currentTime: React.MutableRefObject<number>;
  reset: React.MutableRefObject<boolean>;
  running: React.MutableRefObject<boolean>;
  playerRef: React.MutableRefObject<any>;
}

export const useSubtitleManager = ({
  subtitles,
  currentTime,
  reset,
  running,
  playerRef,
}: SubtitleManagerProps) => {
  const sub = useRef(subtitles[0]);
  if (!sub.current) sub.current = subtitles[0];
  const forceUpdate = useForceUpdate();
  const timer = useRef(0);
  const tick = useRef<any>();
  const timeIncrement = 100;

  const paragraphRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popupStopedVideo = useRef(false);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const popupContent = useRef<JapaneseWord | null>(null);

  const handleClick = useMemo(() => {
    return (newPlacement: PopperPlacementType, word: JapaneseWord) =>
      (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log("Event", event.target);
        console.log("handleClick", word);
        const text = (event.target as any).textContent;
        console.log("Text", text, "popup", popupContent.current?.surfaceForm);
        if (text === popupContent.current?.surfaceForm) {
          popupStopedVideo.current && playerRef.current?.playVideo();
          popupContent.current = null;
          setOpen(false);
          return;
        }
        console.log("Text", (event.target as any).textContent);
        setAnchorEl(event.currentTarget);
        setOpen(true);
        stopVideo();
        setPlacement(newPlacement);
        popupContent.current = word;
      };
  }, []);

  const stopVideo = async () => {
    const isPlaying = (await playerRef.current?.getPlayerState()) === 1;
    popupStopedVideo.current = isPlaying || popupStopedVideo.current;
    await playerRef.current?.pauseVideo();
  };

  const handleClickAway = async (event: MouseEvent | TouchEvent) => {
    
    if (
      paragraphRef.current &&
      !paragraphRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
      popupStopedVideo.current && (await playerRef.current?.playVideo());
      popupContent.current = null;
    }
  };

  useEffect(() => {
    tick.current = setInterval(
      () => {
        if (reset.current) {
          timer.current = 0;
          reset.current = false;
        }

        const totalTime = currentTime.current + timer.current;
        if (sub.current.start > totalTime || sub.current.end <= totalTime) {
          sub.current =
            subtitles.find((s) => s.start <= totalTime && s.end >= totalTime) ||
            ({
              start: 0,
              end: 0,
              text: [],
            } as any);
          setOpen(false);
          popupStopedVideo.current = false;
          forceUpdate();
        }
        timer.current += running.current ? timeIncrement / 1000 : 0;
      },
      timeIncrement,
      sub
    );

    return () => {
      clearInterval(tick.current);
    };
  }, [subtitles]);

  return {
    paragraphRef,
    anchorEl,
    handleClick,
    handleClickAway,
    open,
    placement,
    popupContent,
    sub,
  };
};
