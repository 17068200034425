import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CopyrightDisclamer from "../../../../shared/components/CopyrightDisclamer";
import CssBaseline from "@mui/material/CssBaseline";
import CustomIcon from "../../../../icons/CustomIcon";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Auth } from "aws-amplify";
import { SignInPageStates } from "../SignIn";

interface ForgotPasswordProps {
  setPageState: (pageState: SignInPageStates) => void;
  setEmail: (email: string) => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  setPageState,
  setEmail,
}) => {
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
    });
    const email = data.get("email")?.toString() || "";

    const response = await Auth.forgotPassword(email);
    setEmail(email);
    setPageState("forgotPasswordSubmit");
    console.log("Auth Response", response);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box m={2}>
          <CustomIcon icon="yomimasu-logo" width={36} height={36} />
        </Box>
        <Typography component="h1" variant="h5">
          Recover Password
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, minWidth: "200px" }}
          >
            Recover
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                sx={{ cursor: "pointer" }}
                onClick={() => setPageState("signin")}
                variant="body2"
              >
                Back
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CopyrightDisclamer sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};
export default ForgotPassword;
