import { Auth, Hub } from "aws-amplify";
import axios from "axios";

const apiAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

apiAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    console.error(err);
    if (err.response && err.response.status === 401) {
      Hub.dispatch("auth", { event: "signOut", data: null });
    }
    return Promise.reject(err);
  }
);

apiAxios.interceptors.request.use(
  (req) => {
    return Auth.currentSession().then((session) => {
      const token = session.getIdToken().getJwtToken();
      (req.headers as Record<string, unknown>)[
        "Authorization"
      ] = `Bearer ${token}`;
      return req;
    });
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default apiAxios;
