import SubtitleDisplayer from './WatchVideo/SubtitleDisplayer';
import YouTube, { YouTubeProps } from 'react-youtube';
import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import { useSearchParams } from 'react-router-dom';
import { useVideoInfo, useYoutubeCaptions } from '../../api/youtubeCaptions';
import { useYoutubePlayerState } from './WatchVideo/hooks/useYoutubePlayerState';

const WatchYoutubeVideoContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  backgroundColor: "#e7e9eb", // fix color
  display: "flex",
  flexDirection: "column",
  maxWidth: "100%",
}));

const WatchYoutubeVideo = () => {
  const videoId = useSearchParams()[0].get("videoId") || "";
  const theme = useTheme();
  
  const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isBelowMd = useMediaQuery(theme.breakpoints.down("md"));
  const isBelowLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isBelowXl = useMediaQuery(theme.breakpoints.down("xl"));

  const getYoutubeHeight = () => {
    if (isBelowSm) return "240";
    if (isBelowMd) return "360";
    if (isBelowLg) return "480";
    if (isBelowXl) return "600";
    return "600";
  };

  const opts: YouTubeProps["opts"] = {
    height: getYoutubeHeight(),
    width: "100%",
    playerVars: {
      rel: 0,
    }
  };

  const youtubeVideo = useVideoInfo(videoId)?.data; // For displaying title and thumbnail
  const youtubeCaptions =
    useYoutubeCaptions(videoId, "whisper-medium")?.data || [];

  const {
    onPlayerReady,
    onPlayerStateChange,
    playerRef,
    currentTime,
    reset,
    timerRunning,
  } = useYoutubePlayerState();
  
  // console.log("breakpoint", theme.breakpoints.down("sm"))
  // if (theme.breakpoints.down("sm")) {
  //   opts.height = 240;
  // }

  
  return (
    <WatchYoutubeVideoContainer
      sx={{
        px: 1,
        [theme.breakpoints.up("sm")]: {
          px: 6,
        },
      }}
      px={16}
      boxSizing="border-box"
    >
      <Box maxWidth="1000px">
        <YouTube
          videoId={videoId}
          opts={opts}
          onReady={onPlayerReady}
          onPlay={() => console.log("Playing!")}
          onPause={() => console.log("Stopped!")}
          onStateChange={onPlayerStateChange}
        />
        <SubtitleDisplayer
          subtitles={youtubeCaptions || []}
          currentTime={currentTime}
          reset={reset}
          running={timerRunning}
          playerRef={playerRef}
        />
      </Box>
    </WatchYoutubeVideoContainer>
  );
};
export default WatchYoutubeVideo;
